import React, { useState, createContext, useContext, ReactNode, useEffect } from 'react';
import CreatePartyModal from '@features/games/modals/create-party-modal';
import ProfileModal from '@features/games/profile/profile-modal';
import LogoutModal from '@features/games/modals/logout';
import DepostiModal from '@features/games/deposit';
import WithdrawalModal from '@features/games/withdrawal';
import TermsModal from '@features/signup/terms';
import Alert from '@components/modals/alert';
import ValidatePhoneNumber from '@features/games/profile/validate-phone';
import SettingsModal from '@features/games/settings';
import CostsModal from '@features/costs';
import TipsModal from '@features/tips';
import { EventEmitter } from '@utils/event-emitter';
import BlockGamerModal from '@features/games/settings/block-gamer';
import ReferralModal from '@features/games/profile/referral';
import AffiliateModal from '@features/games/profile/affiliate';
import WaitingPartyModal from '@features/games/modals/waiting-party-modal';
import WinnerModal from '@features/game/winner-modal';
import LoserModal from '@features/game/loser-modal';
import ReportProblemModal from '@features/game/report-problem-modal/report-problem-modal';
import ReplayGameModal from '@features/game/replay-game-modal';
import ReplayGameWaitingModal from '@features/game/replay-game-waiting-modal';
import GameInfoModal from '@features/game/game-infos-modal';
import InfosModal from '@features/infos-modal';
import SurveyModal from '@features/survey-modal';
import GameActionsModal from '@features/game/game-actions-modal';
import GameHistoryDetailsModal from '@features/history/game-history-modal';
import { SHOW_AVAILABLE_UPDATE_MODAL, SHOW_MODAL, SHOW_TRICKS_MODAL } from '@constants/event-emitter';
import OngoingPartyModal from '@features/games/modals/ongoing-party-modal/ongoing-party-modal';
import AvailableUpdateModal from '@features/available-update-modal';
import InstallPWA from '@components/ui/pwa';

export const MODAL_TYPES = {
    CREATE_MODAL: 'CREATE_MODAL',
    DELETE_MODAL: 'DELETE_MODAL',
    UPDATE_MODAL: 'UPDATE_MODAL',
    CREATE_PARTY_MODAL: 'CREATE_PARTY_MODAL',
    WAITING_PARTY_MODAL: 'WAITING_PARTY_MODAL',
    ONGOING_PARTY_MODAL: 'ONGOING_PARTY_MODAL',
    UPDATE_PROFILE_MODAL: 'UPDATE_PROFILE_MODAL',
    LOGOUT_MODAL: 'LOGOUT_MODAL',
    DEPOSIT_MODAL: 'DEPOSIT_MODAL',
    WITHDRAWAL_MODAL: 'WITHDRAWAL_MODAL',
    TERMS_MODAL: 'TERMS_MODAL',
    ALERT_MODAL: 'ALERT_MODAL',
    VERIFY_PHONE_NUMBER: 'VERIFY_PHONE_NUMBER',
    SETTINGS: 'SETTINGS',
    COSTS: 'COSTS',
    TIPS: 'TIPS',
    BLOCK_GAMERS: 'BLOCK_GAMERS',
    REFERRAL_MODAL: 'REFERRAL_MODAL',
    AFFILIATE_MODAL: 'AFFILIATE_MODAL',
    WINNER_MODAL: 'WINNER_MODAL',
    LOSER_MODAL: 'LOSER_MODAL',
    REPORT_PROBLEM_MODAL: 'REPORT_PROBLEM_MODAL',
    REPLY_GAME_MODAL: 'REPLY_GAME_MODAL',
    REPLAY_GAME_WAITING_MODAL: 'REPLAY_GAME_WAITING_MODAL',
    GAME_INFOS_MODAL: 'GAME_INFOS_MODAL',
    INFORMATION_MODAL: 'INFORMATION_MODAL',
    SURVEY_MODAL: 'SURVEY_MODAL',
    GAME_ACTIONS_MODAL: 'GAME_ACTIONS_MODAL',
    GAME_HISTORY_DETAILS: 'GAME_HISTORY_DETAILS',
    AVAILABLE_UPDATE_MODAL: 'AVAILABLE_UPDATE_MODAL',
    INSTALL_PWA_MODAL: 'INSTALL_PWA_MODAL',
};

export type ModalType = (typeof MODAL_TYPES)[keyof typeof MODAL_TYPES];

const MODAL_COMPONENTS: any = {
    [MODAL_TYPES.CREATE_PARTY_MODAL]: CreatePartyModal,
    [MODAL_TYPES.UPDATE_PROFILE_MODAL]: ProfileModal,
    [MODAL_TYPES.LOGOUT_MODAL]: LogoutModal,
    [MODAL_TYPES.DEPOSIT_MODAL]: DepostiModal,
    [MODAL_TYPES.WITHDRAWAL_MODAL]: WithdrawalModal,
    [MODAL_TYPES.TERMS_MODAL]: TermsModal,
    [MODAL_TYPES.ALERT_MODAL]: Alert,
    [MODAL_TYPES.VERIFY_PHONE_NUMBER]: ValidatePhoneNumber,
    [MODAL_TYPES.SETTINGS]: SettingsModal,
    [MODAL_TYPES.COSTS]: CostsModal,
    [MODAL_TYPES.TIPS]: TipsModal,
    [MODAL_TYPES.BLOCK_GAMERS]: BlockGamerModal,
    [MODAL_TYPES.REFERRAL_MODAL]: ReferralModal,
    [MODAL_TYPES.AFFILIATE_MODAL]: AffiliateModal,
    [MODAL_TYPES.WAITING_PARTY_MODAL]: WaitingPartyModal,
    [MODAL_TYPES.ONGOING_PARTY_MODAL]: OngoingPartyModal,
    [MODAL_TYPES.WINNER_MODAL]: WinnerModal,
    [MODAL_TYPES.LOSER_MODAL]: LoserModal,
    [MODAL_TYPES.REPORT_PROBLEM_MODAL]: ReportProblemModal,
    [MODAL_TYPES.REPLY_GAME_MODAL]: ReplayGameModal,
    [MODAL_TYPES.REPLAY_GAME_WAITING_MODAL]: ReplayGameWaitingModal,
    [MODAL_TYPES.GAME_INFOS_MODAL]: GameInfoModal,
    [MODAL_TYPES.INFORMATION_MODAL]: InfosModal,
    [MODAL_TYPES.SURVEY_MODAL]: SurveyModal,
    [MODAL_TYPES.GAME_ACTIONS_MODAL]: GameActionsModal,
    [MODAL_TYPES.GAME_HISTORY_DETAILS]: GameHistoryDetailsModal,
    [MODAL_TYPES.AVAILABLE_UPDATE_MODAL]: AvailableUpdateModal,
    [MODAL_TYPES.INSTALL_PWA_MODAL]: InstallPWA,
};
export interface ModalProps {
    children?: ReactNode;
}

type StoreModalType = {
    onHideModal?: (data?: any) => void;
    modalType?: ModalType;
    modalProps?: { [x: string]: any };
};

type StoreType = {
    [x: ModalType]: StoreModalType;
};

type ModalContext = {
    showModal: (modalType: ModalType, modalProps?: any, onHideModal?: (data?: any) => void) => void;
    hideModal: (modalType?: ModalType, data?: any) => void;
    store: StoreType;
    openedModal?: ModalType;
};

const initalState: ModalContext = {
    showModal: () => {
        //
    },
    hideModal: () => {
        //
    },
    openedModal: undefined,
    store: {},
};

const ModalContext = createContext<ModalContext>(initalState);
export const useModal = () => useContext(ModalContext);

export const ModalContextProvider: React.FC<ModalProps> = ({ children }) => {
    const [store, setStore] = useState<StoreType>({});
    const [openedModal, setOpenedModal] = useState<ModalType>();

    useEffect(() => {
        EventEmitter.on(SHOW_MODAL, (modalType: ModalType, modalProps: any = {}) => {
            showModal(modalType, modalProps);
        });

        EventEmitter.on(SHOW_AVAILABLE_UPDATE_MODAL, (modalType: ModalType, modalProps: any = {}) => {
            showModal(modalType, modalProps);
        });
        EventEmitter.on(SHOW_TRICKS_MODAL, (modalType: ModalType, modalProps: any = {}) => {
            showModal(modalType, modalProps);
        });

        return () => {
            EventEmitter.off(SHOW_MODAL);
            EventEmitter.off(SHOW_AVAILABLE_UPDATE_MODAL);
            EventEmitter.off(SHOW_TRICKS_MODAL);
        };
    }, []);

    const showModal = (modalType: ModalType, modalProps: any = {}, onHideModal?: (data?: any) => void) => {
        setStore({
            ...store,
            [modalType]: {
                ...(store[modalType] ? store[modalType] : {}),
                modalType,
                modalProps,
                onHideModal,
            },
        });
        setOpenedModal(modalType);
    };

    const hideModal = (modalType?: ModalType, data?: any) => {
        if (modalType && store[modalType]) {
            EventEmitter.emit('__hide_' + modalType);
            const copy = { ...store };
            delete copy[modalType];
            store[modalType].onHideModal?.(data);
            setStore(copy);
        } else {
            Object.keys(store).forEach((key) => {
                if (store[key]) {
                    store[key].onHideModal?.(data);
                }
            });
            setStore({});
        }
    };

    const renderComponent = () => {
        return Object.keys(store).map((key) => {
            const ModalComponent = key ? MODAL_COMPONENTS[key] : null;
            if (!ModalComponent) {
                return <></>;
            }
            return <ModalComponent key={key} id={`global-modal-${key}`} {...store[key].modalProps} />;
        });
    };

    return (
        <ModalContext.Provider value={{ store, showModal, hideModal, openedModal }}>
            {renderComponent()}
            {children}
        </ModalContext.Provider>
    );
};
