const files = [
    'attributes',
    'common',
    'confirm-password',
    'contact',
    'costs',
    'dashboard',
    'deposit',
    'home',
    'messages',
    'party',
    'profile',
    'settings',
    'sidebar',
    'signin',
    'signup',
    'withdrawal',
    'game',
    'actions',
    'alert',
    'report-problem',
    'forms',
    'replay-game',
    'infos-modal',
    'transaction',
    'global',
    'terms',
    'tips',
    'forgot-password',
    'pwa'
];

const resources: any = {
    en: {},
    fr: {},
};

files.forEach((file) => {
    resources.en[file] = require('./en/' + file);
    resources.fr[file] = require('./fr/' + file);
});

export default resources;
