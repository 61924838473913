import Avatar1 from '@assets/default-avatar/1.png';
import Avatar3 from '@assets/default-avatar/3.png';
import Avatar4 from '@assets/default-avatar/4.png';
import Avatar8 from '@assets/default-avatar/8.png';
import Avatar22 from '@assets/default-avatar/22.png';

const Avatars = {
    1: Avatar1,
    3: Avatar3,
    4: Avatar4,
    8: Avatar8,
    22: Avatar22,
};

export default Avatars;
