import SigninForm from '@features/signin/signin-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Signin(): React.ReactElement {
    const { t } = useTranslation(['signin']);

    return (
        <div className="section full-page">
            <div className="container grid-full auth-screen">
                <div className="form-side">
                    <div className="form-container">
                        <div className="form-header">
                            <div className="form-icon">
                                <svg
                                    width="48"
                                    height="48"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect width="48" height="48" rx="24" fill="#59C377" fillOpacity="0.16" />
                                    <path
                                        opacity="0.15"
                                        d="M24 23C26.2091 23 28 21.2091 28 19C28 16.7909 26.2091 15 24 15C21.7909 15 20 16.7909 20 19C20 21.2091 21.7909 23 24 23Z"
                                        fill="#59C377"
                                    />
                                    <path
                                        d="M35.5303 21.5303C35.8232 21.2374 35.8232 20.7626 35.5303 20.4697C35.2374 20.1768 34.7626 20.1768 34.4697 20.4697L35.5303 21.5303ZM31.8001 24.1999L31.2697 24.7303C31.5626 25.0232 32.0375 25.0231 32.3304 24.7303L31.8001 24.1999ZM30.5303 21.8696C30.2375 21.5767 29.7626 21.5767 29.4697 21.8696C29.1768 22.1625 29.1768 22.6374 29.4697 22.9303L30.5303 21.8696ZM32 33V33.75C32.4142 33.75 32.75 33.4142 32.75 33H32ZM16 33H15.25C15.25 33.4142 15.5858 33.75 16 33.75V33ZM34.4697 20.4697L31.2697 23.6696L32.3304 24.7303L35.5303 21.5303L34.4697 20.4697ZM29.4697 22.9303L31.2697 24.7303L32.3304 23.6696L30.5303 21.8696L29.4697 22.9303ZM20 27.75H28V26.25H20V27.75ZM31.25 31V33H32.75V31H31.25ZM16.75 33V31H15.25V33H16.75ZM28 27.75C29.7949 27.75 31.25 29.2051 31.25 31H32.75C32.75 28.3766 30.6234 26.25 28 26.25V27.75ZM20 26.25C17.3766 26.25 15.25 28.3766 15.25 31H16.75C16.75 29.2051 18.2051 27.75 20 27.75V26.25ZM27.25 19C27.25 20.7949 25.7949 22.25 24 22.25V23.75C26.6234 23.75 28.75 21.6234 28.75 19H27.25ZM24 22.25C22.2051 22.25 20.75 20.7949 20.75 19H19.25C19.25 21.6234 21.3766 23.75 24 23.75V22.25ZM20.75 19C20.75 17.2051 22.2051 15.75 24 15.75V14.25C21.3766 14.25 19.25 16.3766 19.25 19H20.75ZM24 15.75C25.7949 15.75 27.25 17.2051 27.25 19H28.75C28.75 16.3766 26.6234 14.25 24 14.25V15.75ZM16 33.75H32V32.25H16V33.75Z"
                                        fill="#59C377"
                                    />
                                </svg>
                            </div>
                            <h5 className="form-subtitle">{t('Connectes-toi à ton espace')}</h5>
                            <p className="form-description">{t('Accede a ton espace personnel')}</p>
                        </div>
                        <SigninForm />
                        <div className="separator light"></div>
                        <p>
                            {t("Vous n'avez pas encore de compte, vous pouvez")}
                            <Link to="/auth/signup" className="emp pl-3px">
                                {t('Créer un compte')}
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="auth-scene-side">
                    <div className="auth-scene-content">
                        <div className="scene-title">
                            <h3>{t('Devenez le top Korateur et gagner des bonus')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signin;
