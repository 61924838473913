import { inputChange, inputTouched } from '@redux/reducers/input-reducer';
import React, { ChangeEvent, useEffect, useState, useReducer } from 'react';
import inputReducer from '@reducers/input-reducer';
import Icon from '@components/ui/icon';
import './input.css';
import { Props } from '@components/ui/input/input';
export type Ref = HTMLInputElement;
// Notice here we're using the function declaration with the interface Props
const BInputField: React.FunctionComponent<Props> = (props) => {
    const {
        error: validationError,
        id,
        name,
        placeholder,
        icon,
        type,
        label,
        required,
        onInput,
        value,
        parent,
        disabled,
    } = props;
    //Creating compoent state
    const [error, setError] = useState<string>();
    const [inputIconClass, setInputIconClass] = useState('');
    const [focused, setFocused] = useState(false);
    const [inputState, dispatch] = useReducer(inputReducer, { value: '' });
    const { value: inputValue } = inputState;

    // Initialize the input field with the default value
    useEffect(() => {
        dispatch(inputChange({ val: value ?? '' }));
    }, []);

    useEffect(() => {
        let cz = 'icon-input';
        if (focused) {
            cz += ' focused';
        }
        if (error) {
            cz += ' invalid';
        }
        setInputIconClass(cz);
    }, [focused]);

    useEffect(() => {
        onInput ? onInput(inputValue) : {};
    }, [name, inputValue, onInput]);

    useEffect(() => {
        if (parent && parent.inputs[name] && parent.inputs[name].errors !== undefined) {
            const errors = parent.inputs[name].errors;
            if (errors && errors.hasError) {
                setError(errors.first ?? '');
                return;
            }
        }
        setError('');
    }, [parent]);

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(inputChange({ val: event.target.value }));
    };

    const focusHandler = () => {
        setFocused(true);
        dispatch(inputTouched(true));
    };

    const blurHandler = () => {
        setFocused(false);
    };

    useEffect(() => {
        setError(validationError);
    }, [validationError]);

    return (
        <fieldset className="input-field">
            {label && (
                <label htmlFor={id}>
                    {label} {(required === undefined || required) && <span className="form-require">*</span>}
                </label>
            )}
            <div className={inputIconClass}>
                {icon && <Icon name={icon}></Icon>}
                <input
                    type={type ?? 'input'}
                    onChange={changeHandler}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                    name={name}
                    value={typeof inputValue == 'boolean' ? inputValue + '' : inputValue}
                    id={id}
                    disabled={disabled}
                    placeholder={placeholder ?? ''}
                    className={error ? 'invalid' : ''}
                />
            </div>
            {error && <strong className="invalid">{error}</strong>}
        </fieldset>
    );
};
BInputField.displayName = 'InputField';

export default BInputField;
