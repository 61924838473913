import { AnyAction, configureStore, Dispatch, Middleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import ToastReducer from '@reducers/toast-reducer';
import InputReducer from '@reducers/input-reducer';
import FormReducer from '@reducers/form-reducer';
import SignupReducer from '@redux/reducers/signup-reducer';
import OTPReducer from '@redux/reducers/otp-reducer';
import DopdownReducer from '@redux/reducers/dropdown-reducer';
import RecoveryReducer from '@redux/reducers/recovery-reducer';
import ModalReducer from '@redux/reducers/modal-reducer';
import ChangePhoneNumberReducer from '@redux/reducers/change-phone-reducer';
import DepositReducer from '@redux/reducers/deposit-reducer';
import ConfirmDepositReducer from '@redux/reducers/confirm-deposit-reducer';
import TransactionReducer from '@redux/reducers/transaction-reducer';
import RequireAuthReducer from '@redux/reducers/require-auth-reducer';
import TermsReducer from '@redux/reducers/terms-reducer';
import MenuReducer from '@redux/reducers/menu-reducer';
import VerifyPhoneReducer from '@redux/reducers/verify-phone-reducer';
import EventReducer from '@redux/reducers/event-reducer';
import PartnerReducer from '@redux/reducers/partner-reducer';
import CostsReducer from '@redux/reducers/costs-reducer';
import TipsReducer from '@redux/reducers/tips-reducer';
import PendingGameReducer from '@redux/reducers/pending-game-reducer';
import OngoingGameReducer from '@redux/reducers/ongoing-game-reducer';
import GameReducer from '@redux/reducers/game-reducer';
import SettingsReducer from '@redux/reducers/settings-reducer';
import BlockGamerReducer from '@redux/reducers/block-gamer-reducer';
import ReferralReducer from '@redux/reducers/referral-reducer';
import AppReducer from '@redux/reducers/app-reducer';
import SideBarReducer from '@redux/reducers/sidebar-reducer';

export const store = configureStore({
    reducer: {
        toastStore: ToastReducer,
        inputStore: InputReducer,
        formStore: FormReducer,
        signupStore: SignupReducer,
        OTPStore: OTPReducer,
        dropdownStore: DopdownReducer,
        recoveryStore: RecoveryReducer,
        modalStore: ModalReducer,
        changePhoneStore: ChangePhoneNumberReducer,
        depositStore: DepositReducer,
        confirmDepositStore: ConfirmDepositReducer,
        transactionStore: TransactionReducer,
        authenticationStore: RequireAuthReducer,
        termsStore: TermsReducer,
        menuStore: MenuReducer,
        verifyPhoneStore: VerifyPhoneReducer,
        eventStore: EventReducer,
        partnerStore: PartnerReducer,
        costsStore: CostsReducer,
        tipsStore: TipsReducer,
        pendingGameStore: PendingGameReducer,
        ongoingGameStore: OngoingGameReducer,
        gameStore: GameReducer,
        settingsStore: SettingsReducer,
        blockGamerStore: BlockGamerReducer,
        referralStore: ReferralReducer,
        appStore: AppReducer,
        sidebarStore: SideBarReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware({});
        if (process.env.NODE_ENV !== 'production') {
            middleware.concat([logger as Middleware<any, any, Dispatch<AnyAction>>]);
        }
        return middleware;
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
