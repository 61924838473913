export default {
    CHECK_SERVER_STATUS: '/ping',
    LOGIN_PSEUDO_PWD: '/auth/login',
    REGISTER_OR_LOGIN: '/auth/register-or-login',
    REGISTER: '/account/register',
    QUICK_REGISTER: '/auth/quick-register',
    VERIFY_AUTH_CODE: '/auth/verify-code',
    RESEND_AUTH_CODE: '/auth/resend-code',
    VERIFY_TOKEN: '/auth/verify-token',
    TERMS: '/res/terms',
    POLICY: '/res/policy',
    RULES: '/res/rules',
    LOGOUT: '/account/logout',
    UPDATE_PROFILE: '/account/update-profile',
    CHANGE_NUMBER: '/account/change-number',
    SEND_PHONE_CHANGE_CODE: '/account/send-phone-change-code',
    SEND_PWD_RESET_CODE: '/account/send-password-reset-code',
    RESET: '/account/reset-password',
    HISTORY: '/account/history',
    ADD_REFFERAL_CODE: '/account/add-promo-code',
    COSTS: '/res/costs',
    GAME_INFOS: '/game/infos',
    PENDING_GAMES: '/game/pending-games',
    ONGOING_GAMES: '/game/ongoing-games',
    CREATE_GAME: '/game/create',
    GAME_PLAYERS: '/game/players',
    JOIN: '/game/join',
    QUIT: '/game/quit',
    CANCEL: '/game/cancel',
    START: '/game/start',
    REMOVE_PLAYER: '/game/remove-player',
    LEADERBOARD: '/game/leaderboard',
    LEADERBOARD_BONUSES: '/game/leaderboard/bonuses',
    PROBLEM_REPORT: '/game/report',
    DEPOSIT: '/transaction/deposit',
    CONFIRM_DEPOSIT: '/transaction/deposit/confirm',
    WITHDRAWAL: '/transaction/withdrawal',
    ANSWER_SURVEY: '/user/survey/answer',
    INFOS_VIEW: '/user/info/view',
    REFERRAL_STATS: '/account/referral/stats',
    BLOCK_PLAYER: '/user/blocking/block',
    UNBLOCK_PLAYER: '/user/blocking/unblock',
    BLOCKING_SEARCH_PLAYER: '/user/blocking/findUser',
    BLOCKING_LIST: '/user/blocking/list',
    REFERREE_HISTORY: '/referee/history',
    REMATCH: '/game/rematch',
    REMATCH_QUIT: '/game/rematch/quit',
    LEADERBOARD_PARTNER: '/partner/leaderboard',
    REFERRED_USERS: '/partner/referred-users',
    WITHDRAWAL_PARTNER: '/partner/withdrawal',
};
