import { BlockedUser } from '@models/blocked-user.model';
import { Party } from 'interfaces/party.interface';
import { canJoinParty, partMaxWin, partRequiredAmount } from './game.util';

export const areBlockedListsEqual = (list1: BlockedUser[], list2: BlockedUser[]): boolean => {
    if (list1.length !== list2.length) {
        return false; // Different lengths, so they are not equal
    }

    return list1.every((item, index) => item.id === list2[index].id && item.pseudo === list2[index].pseudo);
};

export const sortPartList = (partListData: Party[], isPendingPart: boolean, balance: number) => {
    return partListData.sort((a, b) => {
        if (a.is_competition && !b.is_competition) return -1;
        if (!a.is_competition && b.is_competition) return 1;

        if (isPendingPart) {
            if (canJoinParty(a, balance) && !canJoinParty(b, balance)) return -1;
            if (!canJoinParty(a, balance) && canJoinParty(b, balance)) return 1;
        }

        return b.bet - a.bet || partRequiredAmount(b) - partRequiredAmount(a) || partMaxWin(b) - partMaxWin(a);
    });
};
