import { Party } from 'interfaces/party.interface';

export const partRequiredAmount = (part: Party) => {
    const bet = part.bet;
    if (part.double_korat) return bet * 4;
    if (part.korat) return bet * 2;
    return bet;
};

export const partMaxWin = (part: Party) => part.max_nb_players * partRequiredAmount(part);

export const canJoinParty = (part: Party, balance: number) => balance >= partRequiredAmount(part);
