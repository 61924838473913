// Layouts
import MainLayout from '@components/layouts/main-layout';
import AppLayout from '@components/layouts/app-layout';
import { renderRoutes, IRoutes } from '@routes/generate-routes';
// Pages
import Welcome from '@pages/welcome';
import Signin from '@pages/signin';
import PageNotFound from '@pages/page-not-found';
import Signup from '@pages/signup';
//import OtpVerification from '@pages/otp-verification';
import ConfirmPassword from '@pages/confirm-password';
import GamerLayout from '@components/layouts/gamer-layout';
import Playing from '@pages/playing';
import ContactUs from '@pages/contact-us';
import PengingParty from '@pages/gamers/pending-party';
import OngoingParty from '@pages/gamers/ongoing-party';
import GameLayout from '@components/layouts/game-layout';
import Game from '@pages/game/game';
import History from '@pages/gamers/history';
import DefaultSidebar from '@components/sidebars/default-sidebar/default-sidebar';
import ForgotPassword from '@pages/forgot-password';
import VerifyResetPasswordCode from '@pages/verify-reset-password-code';

export const routes: IRoutes[] = [
    {
        layout: MainLayout,
        routes: [
            {
                name: 'welcome',
                title: 'Login page',
                component: Welcome,
                path: '/',
                isPublic: true,
            },
            {
                name: 'playing',
                title: 'Palying',
                component: Playing,
                path: '/playing',
                isPublic: true,
            },
            {
                name: 'contact',
                title: 'Contact us',
                component: ContactUs,
                path: '/contact-us',
                isPublic: true,
            },
        ],
    },
    {
        layout: AppLayout,
        routes: [
            {
                name: 'signin',
                title: 'Signin page',
                component: Signin,
                path: '/auth/signin',
                isPublic: true,
            },
            {
                name: 'signup',
                title: 'Signup page',
                component: Signup,
                path: '/auth/signup',
                isPublic: true,
            },
            {
                name: 'verify-password',
                title: 'Verify password code page',
                component: VerifyResetPasswordCode,
                path: '/auth/verify-password',
                isPublic: true,
            },
            {
                name: 'forgot-password',
                title: 'Forgot password page',
                component: ForgotPassword,
                path: '/auth/forgot-password',
                isPublic: true,
            },
        ],
    },
    /*{
        layout: AppLayout,
        routes: [
            {
                name: 'otp-verification',
                title: 'Otp Verification page',
                component: OtpVerification,
                path: '/auth/optverification',
                isPublic: true,
            },
        ],
    },*/
    {
        layout: AppLayout,
        routes: [
            {
                name: 'confirm-password',
                title: 'Confirm password',
                component: ConfirmPassword,
                path: '/auth/confirm-password',
                isPublic: true,
            },
        ],
    },
    {
        layout: MainLayout,
        routes: [
            {
                name: 'home',
                title: 'Home page',
                component: Welcome,
                path: '/home',
            },
            {
                name: 'users',
                title: 'Users',
                hasSiderLink: true,
                routes: [
                    {
                        name: 'list-users',
                        title: 'List of users',
                        hasSiderLink: true,
                        component: Welcome,
                        path: '/users',
                    },
                    {
                        name: 'create-user',
                        title: 'Add user',
                        hasSiderLink: true,
                        component: Welcome,
                        path: '/users/new',
                    },
                ],
            },
        ],
    },
    {
        layout: GamerLayout,
        sidebar: DefaultSidebar,
        routes: [
            /* {
                isPublic: false,
                name: 'dashboard',
                title: 'Dashboard page',
                component: Dashboard,
                path: '/apps/dashboard',
            }, */
            {
                isPublic: false,
                name: 'history',
                title: 'History page',
                component: History,
                path: '/apps/history',
            },
            {
                isPublic: false,
                name: 'pending-game',
                title: 'Pending Games',
                component: PengingParty,
                path: '/apps/games/pending',
            },
            {
                isPublic: false,
                name: 'ongoing-game',
                title: 'Ongoing Games',
                component: OngoingParty,
                path: '/apps/games/ongoing',
            },
        ],
    },
    /* {
        layout: GamerLayout,
        sidebar: ProfileSidebar,
        routes: [
            {
                isPublic: false,
                name: 'korateur',
                title: 'Profile page',
                component: Profile,
                path: '/apps/korateur',
            },
        ],
    }, */
    {
        layout: GameLayout,
        routes: [
            {
                isPublic: false,
                name: 'game',
                title: 'Game',
                component: Game,
                path: '/apps/game/paying/:partyId',
            },
        ],
    },
    {
        layout: MainLayout,
        routes: [
            {
                isPublic: true,
                name: 'not-found',
                title: 'Profile page',
                component: PageNotFound,
                path: '*',
            },
        ],
    },
];
export const Routes = renderRoutes(routes);
