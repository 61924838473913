import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { LOADING } from '@models/status';
import PartyWidget from '@components/widgets/party';
import { Party } from 'interfaces/party.interface';
import Spinner from '@components/ui/spinner';
import { setGame } from '@redux/reducers/game-reducer';
import useUnMount from '@hooks/use-unmount';
import Event from '@constants/events';
import { useOngoingGame } from '@hooks/use-ongoing-game';
import { useSocket } from '@hooks/use-socket';
import { ReactComponent as RefreshIcon } from '@assets/icons/outline/refresh.svg';
import { clearGames } from '@redux/reducers/ongoing-game-reducer';

function OngoingParty(): React.ReactElement {
    const dispatch = useDispatch();
    const { t } = useTranslation(['party']);
    const { showModal } = useModal();
    const { status, games } = useSelector((state: RootState) => state.ongoingGameStore);
    const [_games, _setGames] = useState<Party[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const { socket, isConnected: isSocketDisconnected } = useSocket();
    const { onGetOngoingGames } = useOngoingGame();

    const _onGameStart = (game: Party) => {
        dispatch(setGame(game));
        showModal(MODAL_TYPES.ONGOING_PARTY_MODAL, { game });
    };

    const _onSelectedGameItem = (game: Party) => {
        _onGameStart(game);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const _onFilterGames = () => {
        const filteredGames = games
            .filter((game) => {
                const inputAsNumber = parseFloat(searchInput);

                if (!isNaN(inputAsNumber)) {
                    // If the input is a number, filter by amount greater than the input value
                    return game.bet > inputAsNumber;
                } else {
                    // If the input is not a number, filter by title containing the input string (case-insensitive)
                    return game.title.toLowerCase().includes(searchInput.toLowerCase());
                }
            })
            .sort((a, b) => b.bet - a.bet);

        _setGames(filteredGames);
    };

    useEffect(() => {
        _onFilterGames();
    }, [searchInput, games]);

    useEffect(() => {
        socket?.on(Event.ONGOING_GAME_LIST, onGetOngoingGames);
    }, [socket]);

    useUnMount(() => {
        socket?.off(Event.ONGOING_GAME_LIST, onGetOngoingGames);
    });

    const onRefresh = () => {
        socket?.emit(Event.GET_ONGOING_GAMES);
    };

    useEffect(() => {
        if (!isSocketDisconnected) {
            dispatch(clearGames());
        }
    }, [isSocketDisconnected]);

    return (
        <div className="list-page-content w-100">
            <div className="page-intro w-100">
                <h2 className="page-title">{t('Parties en cours')}</h2>
                <div className="page-filter">
                    <form action="">
                        <div className="input-line">
                            <label htmlFor="sort">{t('Filtrer selon la mise')}</label>
                            <input type="text" onChange={handleInputChange} name="sort" id="sort" />
                        </div>
                    </form>
                </div>
            </div>
            <div className="page-section">
                <div className="list-grid">
                    {_games.map((game, index) => (
                        <PartyWidget
                            party={game}
                            key={index}
                            isPendingPart={false}
                            onSelectGame={_onSelectedGameItem}
                        ></PartyWidget>
                    ))}
                </div>
            </div>
            {(status == LOADING || !_games.length) && (
                <div className="page-section mobile-padding-security">
                    <div className="d-flex align-items-center text-center widget info-card large opaced create-game-card  text-center">
                        <p className="text-center">{t('no_ongoing_party')}</p>
                        <span className="flex-none" style={{ width: 100 }}>
                            <Spinner color="#FFF" />
                        </span>
                    </div>
                </div>
            )}
            <div className="d-flex align-items-center widget large text-center py-0">
                <button className="w-auto success text-center d-flex justify-content-center " onClick={onRefresh}>
                    {t('refresh', { ns: 'actions' })}
                    <span className="icon icon12">
                        <RefreshIcon />
                    </span>
                </button>
            </div>
        </div>
    );
}

export default OngoingParty;
