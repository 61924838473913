import { twoDigits } from '.';

export default class extends Date {
    toFrDate() {
        const d = twoDigits(this.getDate()),
            m = twoDigits(this.getMonth() + 1),
            y = this.getFullYear();

        return `${d}/${m}/${y}`;
    }

    toFrCompactDate() {
        const d = twoDigits(this.getDate()),
            m = twoDigits(this.getMonth() + 1),
            y = this.getFullYear().toString().substr(-2);

        return `${d}/${m}/${y}`;
    }

    toFrTime() {
        const h = twoDigits(this.getHours()),
            i = twoDigits(this.getMinutes()),
            s = twoDigits(this.getSeconds());

        return `${h}:${i}:${s}`;
    }

    toFrCompactTime() {
        const h = twoDigits(this.getHours()),
            i = twoDigits(this.getMinutes());

        return `${h}:${i}`;
    }

    toFrDatetime() {
        return this.toFrDate() + ' ' + this.toFrTime();
    }
}
