import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RootState } from '@redux/store';
import { useSelector } from 'react-redux';
import { LOADING } from '@models/status';
import PartyWidget from '@components/widgets/party';
import { Party } from 'interfaces/party.interface';
import { setGame } from '@redux/reducers/game-reducer';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import Spinner from '@components/ui/spinner';
import { useLocation } from 'react-router-dom';
import { sortPartList } from '@utils/list.util';
import { useAuth } from '@hooks/use-auth';
import { ReactComponent as RefreshIcon } from '@assets/icons/outline/refresh.svg';
import { useSocket } from '@hooks/use-socket';
import Event from '@constants/events';
import useUnMount from '@hooks/use-unmount';
import { usePendingGame } from '@hooks/use-pending-game';
import { clearGames } from '@redux/reducers/pending-game-reducer';

function PendingParty(): React.ReactElement {
    const dispatch = useDispatch();
    const { user } = useAuth();
    const { showModal } = useModal();
    const { t } = useTranslation(['party']);
    const { status, games } = useSelector((state: RootState) => state.pendingGameStore);
    const [_games, _setGames] = useState<Party[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const location = useLocation();
    const { game: newGame } = location.state || {};
    const { socket, isConnected: isSocketDisconnected } = useSocket();
    const { onGetPendingGames } = usePendingGame();

    const _onGameStart = (game: Party) => {
        dispatch(setGame(game));
        showModal(MODAL_TYPES.WAITING_PARTY_MODAL, { game });
        //setSelectedWaitingGame(game);
    };

    const _onSelectedGameItem = (game: Party) => {
        _onGameStart(game);
        //setSelectedPendingPart(game);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const _onFilterGames = () => {
        let filteredGames: Party[] = games.filter((game) => {
            const inputAsNumber = parseFloat(searchInput);

            if (!isNaN(inputAsNumber)) {
                // If the input is a number, filter by amount greater than the input value
                return game.bet > inputAsNumber;
            } else {
                // If the input is not a number, filter by title containing the input string (case-insensitive)
                return game.title.toLowerCase().includes(searchInput.toLowerCase());
            }
        });
        filteredGames = sortPartList(filteredGames, true, user?.balance ?? 0);
        _setGames(filteredGames);
    };

    useEffect(() => {
        _onFilterGames();
    }, [searchInput, games]);

    useEffect(() => {
        socket?.on(Event.PENDING_GAME_LIST, onGetPendingGames);
    }, [socket]);

    useUnMount(() => {
        socket?.off(Event.PENDING_GAME_LIST, onGetPendingGames);
    });

    useEffect(() => {
        if (!isSocketDisconnected) {
            dispatch(clearGames());
        }
    }, [isSocketDisconnected]);

    useEffect(() => {
        if (newGame && games.some((g) => g.id === newGame.id)) {
            _onSelectedGameItem(newGame);
        }
    }, [newGame, games]);

    const onRefresh = () => {
        socket?.emit(Event.GET_PENDING_GAMES);
    };

    return (
        <div className="list-page-content w-100">
            <div className="page-intro w-100">
                <h2 className="page-title">{t('Parties en attente')}</h2>
                <div className="page-filter">
                    <form action="">
                        <div className="input-line">
                            <label htmlFor="sort">{t('Filtrer selon la mise')}</label>
                            <input type="text" onChange={handleInputChange} name="sort" id="sort" />
                        </div>
                    </form>
                </div>
            </div>
            <div className="page-section">
                <div className="list-grid">
                    {_games.map((game, index) => (
                        <PartyWidget
                            party={game}
                            key={index}
                            isPendingPart={true}
                            onSelectGame={_onSelectedGameItem}
                        ></PartyWidget>
                    ))}
                </div>
            </div>
            {(status == LOADING || !_games.length) && (
                <div className="page-section mobile-padding-security">
                    <div className="d-flex align-items-center widget info-card large opaced create-game-card  text-center">
                        <p className="text-center">{t('no_pending_party')}</p>
                        <span className="flex-none" style={{ width: 100 }}>
                            <Spinner color="#FFF" />
                        </span>
                    </div>
                </div>
            )}
            <div className="d-flex align-items-center widget large text-center py-0">
                <button className="w-auto success text-center d-flex justify-content-center " onClick={onRefresh}>
                    {t('refresh', { ns: 'actions' })}
                    <span className="icon icon12">
                        <RefreshIcon />
                    </span>
                </button>
            </div>
        </div>
    );
}

export default PendingParty;
