// useCreatePartyModal.ts
import { d, e } from '@utils/encryption-wrapper';
import { MODAL_TYPES, useModal } from '@contexts/global-modal-context';
import { Game } from 'interfaces/game.interface';
import { useDispatch } from 'react-redux';
import { resetGame, setGame, setGameToReplay } from '@redux/reducers/game-reducer';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@utils/alert';
import { useTranslation } from 'react-i18next';
import { useAuth } from './use-auth';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { EventEmitter } from '@utils/event-emitter';
import { removeGame } from '@redux/reducers/pending-game-reducer';
import { useSocket } from './use-socket';
import Event from '@constants/events';
import { Party } from 'interfaces/party.interface';
import gameService from '@services/game-service';
import { useToast } from './use-toast';
import { useEffect, useState } from 'react';

const CLOSE_MODAL_TIMEOUT = 350;

export const useGamePLaying = () => {
    const { hideModal, showModal } = useModal();
    const { isReferee, user } = useAuth();
    const { currentPlayer, game } = useSelector((state: RootState) => state.gameStore);
    const { t } = useTranslation(['alert']);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { socket } = useSocket();
    const toast = useToast();
    const [canReplayGame, setCanReplayGame] = useState<boolean>(true);
    let hasRestartedGame = false;

    const resetAndNavigate = () => {
        dispatch(removeGame(game?.part.id as string));
        dispatch(resetGame());
        navigate('/apps/games/pending', { replace: true });
    };

    const onReplayGameListeners = () => {
        socket?.on(Event.REMATCH_GAME, onRematchGameEvent);
        socket?.on(Event.REMATCH_GAME_UPDATE, onRematchGameUpdateEvent);
        socket?.on(Event.REMATCH_GAME_CANCEL, onRematchGameCancelEvent);
        socket?.on(Event.REMATCH_GAME_START, onRematchGameStartEvent);
    };

    const offReplayGameListeners = () => {
        socket?.off(Event.REMATCH_GAME, onRematchGameEvent);
        socket?.off(Event.REMATCH_GAME_CANCEL, onRematchGameCancelEvent);
        socket?.off(Event.REMATCH_GAME_START, onRematchGameStartEvent);
        socket?.off(Event.REMATCH_GAME_UPDATE, onRematchGameUpdateEvent);
    };

    useEffect(() => {
        if (!canReplayGame) {
            _onEndModalOpen();
        }
    }, [canReplayGame]);

    const _onEndModalOpen = () => {
        hideModal();
        setTimeout(() => {
            if (game && game.isFinish) {
                showEnGameModal(game);
            }
        }, CLOSE_MODAL_TIMEOUT);
    };

    const showEnGameModal = (game: Game) => {
        if (game.winner.id === user?.id) {
            showWinnerModal(game);
        } else {
            showLoserModal(game);
        }
    };

    const showWinnerModal = (game: Game) => {
        showModal(MODAL_TYPES.WINNER_MODAL, {
            gain: game.part.gain,
            commissions: game.part.commissions,
            winType: game.win_type,
            onReportProblem: onReportProblem,
            onQuitGame: onResign,
            onReplayGame: onReplayGame,
        });
    };

    const showLoserModal = (game: Game) => {
        showModal(MODAL_TYPES.LOSER_MODAL, {
            winner: game.winner.pseudo,
            winType: game.win_type,
            loss: game.part.gain,
            onReportProblem: onReportProblem,
            onQuitGame: onResign,
            onReplayGame: onReplayGame,
        });
    };

    const onReportProblem = () => {
        if (!game?.isFinish) {
            Alert.alert(t('warning_label', { ns: 'alert' }), t('cannot_report', { ns: 'alert' }));
            return;
        }
        hideModal();
        showModal(MODAL_TYPES.REPORT_PROBLEM_MODAL, {});
    };

    const onRematchGameEvent = (updatedReplayGameData: string, timer: string) => {
        hasRestartedGame = false;
        const updatedReplayGame: Party = d(updatedReplayGameData);
        timer = d(timer);
        const player_ids = updatedReplayGame.players.map((player) => player.user_id);
        if (user) {
            dispatch(setGameToReplay(updatedReplayGame));
            EventEmitter.emit('__replay_time_left__', timer);
            if (
                game &&
                updatedReplayGame.parent_game &&
                updatedReplayGame.parent_game._id == game.part.id &&
                !player_ids.includes(user.id)
            ) {
                hideModal();
                setTimeout(() => {
                    showModal(MODAL_TYPES.REPLY_GAME_MODAL, {
                        creator: updatedReplayGame.players[0],
                        timer: timer,
                        onReplayGame: onReplayGame,
                    });
                }, CLOSE_MODAL_TIMEOUT);
            }
        }
    };

    const onRematchGameCancelEvent = () => {
        if (game) {
            hideModal();
            setTimeout(() => {
                //this.setState({ replayTimeLeft : null, isReplayBtnVisible : false });
                setCanReplayGame(false);
                _onEndModalOpen();
                toast?.info(t('game_cancelled', { ns: 'game' }));
            }, CLOSE_MODAL_TIMEOUT);
        }
    };

    const onRematchGameUpdateEvent = (updatedReplayGame: string) => {
        dispatch(setGameToReplay(d(updatedReplayGame)));
    };

    const onRematchGameStartEvent = (data: string) => {
        hasRestartedGame = true;
        EventEmitter.emit('replayGame', data);
        dispatch(setGame(d(data)));
    };

    const onReplayGame = () => {
        if (!game) {
            return;
        }
        if (game.part && game.part.is_competition) {
            Alert.alert(t('inf_label', { ns: 'alert' }), t('replay_competition_error', { ns: 'alert' }));
            return;
        }
        gameService
            .replayGame(game.part.id)
            .then((data: Party) => {
                hideModal();
                setTimeout(() => {
                    dispatch(setGameToReplay(data));
                    if (!hasRestartedGame) {
                        showModal(MODAL_TYPES.REPLAY_GAME_WAITING_MODAL, {
                            oldGame: game,
                            game: data,
                        });
                    }
                }, CLOSE_MODAL_TIMEOUT);
            })
            .catch((error) => Alert.alert(t('global.oops'), error.message));
    };

    const onQuit = (callback?: any) => {
        offReplayGameListeners();

        EventEmitter.emit('__resign__');

        hideModal();

        if (callback) callback();

        resetAndNavigate();
    };

    const onResign = () => {
        if (!game) {
            onQuit();
            return;
        }

        if (!isReferee) {
            if (game.isFinish || (currentPlayer && currentPlayer.resigned)) {
                onQuit();
                return;
            }

            if (currentPlayer && currentPlayer.playCards.length >= 4) {
                Alert.alert(t('warning_label', { ns: 'alert' }), t('cannot_leave_game_part', { ns: 'alert' }));
                return;
            }
        }

        Alert.alert(
            t('quit_game_label', { ns: 'alert' }),
            t('quit_part_question', { ns: 'alert' }),
            [
                {
                    text: t('no', { ns: 'global' }),
                    onPress: () => {
                        //
                    },
                },
                {
                    text: t('yes', { ns: 'global' }),
                    onPress: () => {
                        if (game) {
                            sendResignEvent(game);
                        }
                    },
                },
            ],
            { cancelable: true },
        );
    };

    const sendResignEvent = (game: Game) => {
        socket?.emit(Event.RESIGN, e(game.part.id));
        if (isReferee) {
            onQuit();
        }
    };

    return {
        onResign,
        onQuit,
        onReplayGameListeners,
        offReplayGameListeners,
        onReportProblem,
        showEnGameModal,
        hasRestartedGame,
    };
};
